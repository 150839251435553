@import url(https://fonts.googleapis.com/css?family=PT+Sans:700|Pacifico|Changa+One);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700italic,300,400italic);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-table {
    width: 100%;
    padding-bottom: 1.5em;
    color: white;
    text-align: center;
}

    .header-table h1 {
  font-weight: 100;
  font-size: 29px;
  font-family: 'PT Sans', sans-serif;
  margin: 0.75em 0 0.30em;
  color: #afafaf;
  border-bottom: 3px solid #033763;
    }

.header-table .servertypeinfowrapper {
  margin: 0.75em 0 0.30em;
  color: #DADADA;
  border-bottom: 3px solid rgba(30, 181, 64, 0.75);
}

.header-table .servertypeinfowrapper2 {
  margin: 0.75em 0 0.30em;
  color: #DADADA;
  border-bottom: 3px solid #D44F2F;
}

.header-table .servertypeinfowrapper3 {
  margin: 0.75em 0 0.30em;
  color: #DADADA;
  border-bottom: 3px solid #10A8E4;
}

    .header-table .servertypename {
  font-weight: 600;
  font-size: 29px;
  font-family: 'PT Sans', sans-serif;
  color: rgba(3, 187, 43, 0.83);
  padding: 0 6px;
    }

    .header-table .servertypenamets {
  font-weight: 600;
  font-size: 29px;
  font-family: 'PT Sans', sans-serif;
  color: #20BFFF;
  padding: 0 6px;
    }

    .header-table .servertypenamerust {
  font-weight: 600;
  font-size: 29px;
  font-family: 'PT Sans', sans-serif;
  color: #D44F2F;
  padding: 0 6px;
    }

.header-table .servertypeinfo {
  font-weight: 100;
  font-size: 29px;
  font-family: 'PT Sans', sans-serif;
  padding: 0 6px;
    }

.header-table h1:before {
margin: 20px 0 0 -60px;
}

.header-table h1:after {
margin: 20px 0 0 10px;
}

.bodywrapper {
background: rgba(0, 0, 0, 0.63);
}

.main-list {
padding-left: 0;
display: inline-block;
margin: 0 15px;
}

    .main-list li {
        display: inline-block;
        float: left;
        margin: 0 10px;
    }

        .main-list li p {
            font-size: 1.2em;
        }



body {
    background: #dce1df;
    color: #4f585e;
    font-family: 'Roboto condensed', sans-serif;
    text-rendering: optimizeLegibility;
    margin: 0;
    padding: 0;
}

a.btn {
    background: #0096a0;
    border-radius: 4px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
    color: #ffffff;
    display: inline-block;
    padding: 6px 30px 8px;
    text-decoration: none;
    cursor: pointer;
}

a.btnsmall {
background: #23A12D;
border-radius: 4px;
box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
color: #ffffff;
display: inline-block;
padding: 0px 12px 0px;
font-size: 12px;
text-decoration: none;

}				

a.btnsmall-grey {
background: #333333 !important;
}

.no-touch a.btn:hover {
    background: #00a2ad;
    box-shadow: 0 8px 2px 0 rgba(0, 0, 0, 0.075);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);

}

.no-touch a.btn:active,
a.btn:active, a.btnsmall:active {
    background: #008a93;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);
    -webkit-transform: translate3d(0, 1px, 0);
    transform: translate3d(0, 1px, 0);

}

.headertitle {
text-decoration: underline;
text-align: center;
margin: 12px 0 27px;
font-size: 18px;
color: #333;
font-weight: bold
}

.headertitle-top {
text-decoration: underline;
text-align: center;
margin: 9px 0;
font-size: 18px;
color: #333;
}

a.centerbtn {
    border-radius: 4px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
    color: #ffffff;
    display: inline-table;
    margin: 0 4px 9px;
    padding: 6px 0 8px;
    min-width: 120px;
    text-decoration: none;

}

a.playnowcolor {
    background: rgba(35,161,45,0.75);
}

a.playnowcolor:hover {
background: #23A12D;
}

a.playnowcolorpei {
    background: #BF8500;
}

a.playnowcolormili {
    background: rgba(255, 52, 3, 0.61);
}

a.playnowcolormili:hover {
background: #BF2E16;
}

a.forumscolor {
    background: rgba(0,150,160,0.78);
}

a.forumscolor:hover {
background: #0096a0;
}

.top-links-wrapper {
    margin: 0 auto 15px auto;
    text-align: center;
}

div.card {
    background: #ffffff;

    text-align: left;

    margin: 6px 3px 9px 10px;
box-shadow: 0px 0px 23px 4px rgba(0,0,0,0.45);
border-radius: 3px;
}

    div.card img {
        width: 100%;
border-radius: 3px;
    }

    div.card div.card-title {
background: #ffffff;
padding: 6px 15px 6px;
    }

        div.card div.card-title a.toggle-info {
            border-radius: 32px;
            height: 32px;
            padding: 0;
            right: 15px;
            top: 14px;
            width: 32px;
        }

            div.card div.card-title a.toggle-info span {
                background: #ffffff;
                display: block;
                height: 2px;
                top: 16px;

                width: 12px;
            }

                div.card div.card-title a.toggle-info span.left {
                    right: 14px;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                div.card div.card-title a.toggle-info span.right {
                    left: 14px;
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }

        div.card div.card-title h2 {
            font-size: 21px;
            font-weight: 400;
            letter-spacing: -0.05em;
            margin: 0;
            padding: 7px;
            font-family: 'PT Sans', sans-serif;
        }

            div.card div.card-title h2 small {
                display: block;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: -0.025em;
            }

    div.card div.card-description {
padding-left: 2px;
font-size: 14px;
line-height: 31px
    }

    div.card div.card-actions {
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.075);
        padding: 10px 15px 20px;
        text-align: center;
    }

    div.card div.card-flap {
        background: #d9d9d9;
        width: 100%;

    }




i.playnowicon
{
font-size: 17px;
top: 1px;
margin-right: 2px;
}

div.card.show {

}

    div.card.show div.card-title a.toggle-info {
        background: #BF0040 !important;
    }

        div.card.show div.card-title a.toggle-info span {
            top: 15px;
        }

            div.card.show div.card-title a.toggle-info span.left {
                right: 10px;
            }

            div.card.show div.card-title a.toggle-info span.right {
                left: 10px;
            }

    div.card.show div.card-flap {
        background: #ffffff;

    }

    div.card.show div.flap1 {
border-top: 1px dashed #D2D2D2;
border-bottom: 3px solid #3D4A3D;
    }

    div.card.show div.flap2 {

    }

.container {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
}

.lastcont {
margin-bottom: 5.2em;			
}

.card-description ul li {
    font-size: 18px;
}

    .card-description ul li strong {
        color: #A70000;
    }

header {
background: rgba(0, 0, 0, 0.57) !important;
}

footer {
background: rgba(46,48,55,0.96);
    width: 100%;
    display: inline-block;

}

.quater {
    width: 25%;
    float: left;
    text-align: center;
    padding-top: 68px;
    padding-bottom: 96px;
    height: 45px;
}

footer p {
    color: #ADADAD;
}

footer a {
    color: #ADADAD;
text-decoration:none;
}

.footer a:link, .footer a:visited {
color: #ADADAD;
text-decoration:none;
}

footer span {
    color: #fff;
    font-size: 20px !important;
}

.last-col {
    background-color: rgba(0,0,0,0.3) !important;
}

.cards1 {
    text-align: center;
}

.cards {
    text-align: center;
}

    .last-col a {
        color: rgba(0,0,0,0.3);
    }

        .last-col a:hover span,
        .last-col a:active span {
            color: #c0392b;
        }

    .last-col span {
        margin: 0 5px;
        color: #919495;
    }

body {
  background-image: url(https://pandahut.net/wp-content/uploads/2017/04/pandahut-youtube-channel-illustration-2560x1440-low1.jpg);
background-repeat: repeat;
background-attachment: fixed;
background-position: center;
}

.modal {
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 2em;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    font-size: 1em;

    padding: 10px 5px;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    /*position: absolute;
    display: block; */
    text-align: center;
    float: center;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
    
  }

  .popup-content {
    background-color: #292d3e;
    color: #fff;
    border: 1px solid #464545;
}
.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    place-content: center;
  }
.button {
    text-align: center;

}

.btn {



    cursor: pointer;

  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
  
  background-color: #2ecc71;
  color: #ecf0f1;
  
  transition: background-color .3s;
}

.btn:hover, .btn:focus {
  background-color: #27ae60;
}

.btn > * {
  position: relative;
}

.btn span {
  display: block;
  padding: 12px 24px;
}

.btn:before {
  content: "";
  
  top: 50%;
  left: 50%;
  
  width: 0;
  padding-top: 0;
    
  border-radius: 100%;
  
  background-color: rgba(236, 240, 241, .3);
  
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn:active:before {
  width: 120%;
  padding-top: 120%;
  
  transition: width .2s ease-out, padding-top .2s ease-out;
}





.btn.orange {
  background-color: #e67e22;
}

.btn.orange:hover, .btn.orange:focus {
  background-color: #d35400;
}

.btn.red {
  background-color: #e74c3c;
}

.btn.red:hover, .btn.red:focus {
  background-color: #c0392b;
}



.servers {
text-align: center;
font-size: 45px;
color: #fff;
font-family: 'Montserrat', sans-serif;
margin: 0 0 .6em 0;
padding: .6em 0 !important;
}


.features {
padding: 18px 10px 21px 11px;
background: #fff url("https://pandahut.net/wp-content/themes/zerius/images/shattered.png");
width: 98%;
margin-left: auto;
margin-right: auto;
margin-top: 25px;
margin-bottom: 35px;
}

    .features ul {
list-style-type: none;
margin-bottom: 12px;
    }

        .features ul li strong {
            color: #c0392b;
        }

        .features ul li em {
            color: #0096a0;
        }

.features ul li ul li {
  list-style-type: circle;
}

.headline {
    font-size: 20px;
border-bottom: 1px solid #2B4E0B;
}

@media(max-width: 600px) {
    .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
        padding: 0.2em !important;
    }

    .header-table h1:before, .header-table h1:after {
        width: 20px;
    }

    .header-table h1 {
        font-size: 21px;
    }

        .header-table h1:before {
            margin: 7px 0 0 0;
            left: 20px;
        }

        .header-table h1:after {
            margin: 7px 0 0 0;
            right: 20px;
        }

    .quater {
        width: 100%;
        float: none;
        padding: 10px 0;
        margin: 30px 0;
        height: 60px;
    }

    div.card div.card-title h2 {
        font-size: 18px;
    }
}


@media(max-width: 400px) {
    .header-table h1:before, .header-table h1:after {
        width: 0;
    }
}


.gridnormalstretchy {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    margin-top: 3em;
}



@media screen and (max-width: 400px) {
    .gridnormalstretchy {
        width:100% !important;

    }
}



@media screen and (max-width: 1400px) {
    .gridnormalstretchy {
        grid-template-columns: repeat(3,1fr);

    }
}


@media screen and (max-width: 1000px) {
    .gridnormalstretchy {
        grid-template-columns: repeat(2,1fr);

    }
}

@media screen and (max-width: 760px) {
    .gridnormalstretchy {
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
        grid-template-columns: repeat(1,1fr);
    }
}

@media screen and (max-width: 760px) {
    .SelectionServerType {
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
   
    }
}

label {
    padding: 10px;
    margin:0 0 10px;
    color:white;
   }
   
   label:hover {
    background:black;
    cursor:pointer;
   }

.SelectionServerType {
    text-align: center;
    /*margin-top: 2%; */
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center !important;
            justify-content: center !important;
    /*margin-bottom: 5% */
}
.bluelabel {
    border:1px solid #007bff;
    background-color: #007bff;
    border-color: #007bff;
}
.greenlabel {
    border:1px solid #28a745;
    background-color: #28a745;
    border-color: #28a745;
}
.yellowlabel {
    border:1px solid yellow;
    background-color: yellow;
    border-color: yellow;
}
